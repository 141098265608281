$family-sans-serif: BlinkMacSystemFont, -apple-system, "Muli", "Helvetica", "Arial", sans-serif;
// $card-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);

@import "~bulma/bulma.sass";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

#name_banner{
  background-image: -webkit-linear-gradient(to bottom right, #1fa2ff, #12d8fa, #a6ffcb);
  background-image: linear-gradient(to bottom right, #1fa2ff, #12d8fa, #a6ffcb);
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  // height: 80vh;
}

#skillsDiv{
  // color: transparent;
  background: -webkit-linear-gradient(20deg, rgba(49,100,255,1) 23.2%,  rgba(0,238,179,1) 100.5% );
  background-image: linear-gradient(20deg, rgba(49,100,255,1) 10%,  rgba(0,238,179,1) 95%  );
  // box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  background-clip: text;
  -webkit-background-clip: text;
}

.card{
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.gradientBack{
  background-image: -webkit-linear-gradient(to bottom right, #1fa2ff, #12d8fa, #a6ffcb);
  background-image: linear-gradient(to bottom right, #f953c6, #b91d73);
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.slick-prev:before, .slick-next:before{
  color: $grey;
}

.link{

  transform: rotate(-90deg);

  /* Legacy vendor prefixes that you probably don't need... */

  /* Safari */
  -webkit-transform: rotate(-90deg);

  /* Firefox */
  -moz-transform: rotate(-90deg);

  /* IE */
  -ms-transform: rotate(-90deg);

  /* Opera */
  -o-transform: rotate(-90deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}